import styled from "@emotion/styled";

const Subtitle2 = styled.div`
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #545961;
  //for inputs and links list
`;

export default Subtitle2;
