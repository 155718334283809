import styled from "@emotion/styled";
import React, { useState } from "react";
import Header from "@components/Header";
import { layoutStyle } from "@components/Layout";
import Input from "@components/Input";
import { useStores } from "@stores";
import Button from "@components/Button";
import { ROUTES } from "@stores/RouterStore";
import { Redirect } from "react-router-dom";
import { useAlert } from "react-alert";
import { validateEmail } from "@src/utils";
import HelpButton from "@components/HelpButton";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {
  title?: string;
  register?: boolean;
  subtitle?: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${layoutStyle};

  & > * {
    padding: 8px 0;
  }
`;
const Subtitle = styled.div`
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;
const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

const EmailAuth: React.FC<IProps> = ({ title, register, subtitle }) => {
  const { accountStore, routerStore } = useStores();
  const dictionary = useLanguage();
  const alert = useAlert();

  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setEmailValid] = useState<boolean>(true);

  const [isEmailSent, setEmailSent] = useState(false);

  const domain = `${window.location.protocol}//${window.location.host}`;
  const path = register ? ROUTES.FINISH_REGISTER : ROUTES.FINISH_SIGN_UP;
  const cardId = routerStore.getCardId(ROUTES.REGISTER_EMAIl);

  const registerQuery = new URLSearchParams({ cardId: cardId ?? "", email });
  const signInQuery = new URLSearchParams({ email });

  const actionCodeSettings = {
    url: register
      ? `${domain}${path}?${registerQuery.toString()}`
      : `${domain}${path}?${signInQuery.toString()}`,
    handleCodeInApp: true,
  };
  const handleSendEmail = async () => {
    if (!isEmailValid) return;
    await accountStore.sendSignInLinkToEmail(email, actionCodeSettings);
    setEmailSent(true);
    alert.success(dictionary.hasBeenSent);
  };
  const handleValidation = () => {
    const isValid = validateEmail(email);
    setEmailValid(isValid);
  };
  if (register && cardId == null) return <Redirect to={ROUTES.OOPS} />;
  return (
    <Root>
      <Header style={{ marginBottom: 82 }} />
      <Title style={{ paddingBottom: 14 }}>{title ?? "Вход"}</Title>
      <Subtitle style={{ paddingBottom: 30 }}>
        {subtitle ?? dictionary.authToEdit}
      </Subtitle>
      <Input
        style={{ marginTop: 15 }}
        placeholder={dictionary.email}
        value={email}
        onChange={setEmail}
        onBlur={handleValidation}
        onFocus={() => setEmailValid(true)}
        error={!isEmailValid ? dictionary.wrongEmail : undefined}
      />
      <Button
        disabled={isEmailSent || !isEmailValid}
        onClick={handleSendEmail}
        style={{ marginTop: 64 }}
      >
        {!isEmailSent ? dictionary.next : dictionary.hasBeenSent}
      </Button>
      <HelpButton />
    </Root>
  );
};
export default EmailAuth;
