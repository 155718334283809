import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { CodeInput } from "@components/Input";
import TimeoutButton from "@src/screens/auth/PhoneAuth/TimeoutButton";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {
  phoneNumber?: string;
  resetPhone: () => void;
  continueWithCode: (code: string) => Promise<string | null>;
  sendCodeAgain: () => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

const PhoneNum = styled.div`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #afafaf;
  position: absolute;
  left: 0;
  top: -67px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const ErrorText = styled.div`
  font-family: Inter, Graphik LCG;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #ff0000;
  opacity: 0.9;
  position: absolute;
  bottom: -28px;
  left: 0;
`;

const CodeConfirm: React.FC<IProps> = ({
  phoneNumber,
  continueWithCode,
  sendCodeAgain,
  resetPhone,
}) => {
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [seconds, setSeconds] = React.useState(50);
  const [loading, setLoading] = React.useState(false);
  const dictionary = useLanguage();
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  });
  const handleChange = async (code: string) => {
    setCode(code);
    if (code.length === 6) {
      setLoading(true);
      const error = await continueWithCode(code);
      error != null && setError(error);
      setSeconds(50);
    }
    setLoading(false);
  };

  return (
    <Root>
      <PhoneNum onClick={resetPhone}>
        <Arrow />
        {formatPhoneNumberIntl(phoneNumber as any)}
      </PhoneNum>
      <Title>{dictionary.smsCode}</Title>
      <TimeoutButton onClick={sendCodeAgain} />
      <InputWrapper>
        <CodeInput
          disabled={loading}
          invalid={error != null}
          value={code}
          onChange={handleChange}
          onFocus={() => setError(null)}
          placeholder={dictionary.smsCode}
          type="number"
          fields={6}
        />
        <ErrorText>{error}</ErrorText>
      </InputWrapper>
      <div style={{ marginTop: 30 }}>
        {seconds === 0 ? dictionary.anotherWayToRegister : null}
      </div>
    </Root>
  );
};

const Arrow = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: 8 }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.10847 0.792893C9.499 1.18342 9.499 1.81658 9.10847 2.20711L4.31558 7H15.9014C16.4537 7 16.9014 7.44772 16.9014 8C16.9014 8.55228 16.4537 9 15.9014 9H4.31558L9.10847 13.7929C9.499 14.1834 9.499 14.8166 9.10847 15.2071C8.71795 15.5976 8.08478 15.5976 7.69426 15.2071L1.19426 8.70711C0.803736 8.31658 0.803736 7.68342 1.19426 7.29289L7.69426 0.792893C8.08478 0.402369 8.71795 0.402369 9.10847 0.792893Z"
      fill="#2934D0"
    />
  </svg>
);

export default CodeConfirm;
