import React from "react";
import Button, { BUTTON_TYPES } from "@components/Button";
import { Redirect, useHistory } from "react-router-dom";
import { ROUTES } from "@stores/RouterStore";
import { useStores } from "@stores";
import SplashScreen from "@components/SplashScreen";
import { useObserver } from "mobx-react-lite";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const LoginSplashScreen: React.FC<IProps> = () => {
  const history = useHistory();
  const { accountStore } = useStores();
  const dictionary = useLanguage();
  const isLoggedIn = useObserver(() => accountStore.isLoggedIn);
  if (isLoggedIn) return <Redirect to={ROUTES.EDIT} />;
  return (
    <SplashScreen
      title={dictionary.welcome}
      subtitle={dictionary.sameWayToAuthForLogin}
    >
      <Button
        onClick={() =>
          accountStore.signInWithApple().then(() => history.push(ROUTES.ROOT))
        }
        buttonType={BUTTON_TYPES.WHITE}
        style={{ marginTop: 25 }}
      >
        {dictionary.loginApple}
      </Button>
      <Button
        onClick={() =>
          accountStore.signInWithGoogle().then(() => history.push(ROUTES.ROOT))
        }
        buttonType={BUTTON_TYPES.WHITE}
        style={{ marginTop: 25 }}
      >
        {dictionary.loginGoogle}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.LOGIN_EMAIl)}
        buttonType={BUTTON_TYPES.WHITE}
        style={{ marginTop: 25 }}
      >
        {dictionary.loginEmail}
      </Button>
      <Button
        onClick={() => history.push(ROUTES.LOGIN_PHONE)}
        buttonType={BUTTON_TYPES.WHITE}
        style={{ marginTop: 25 }}
      >
        {dictionary.loginPhone}
      </Button>
    </SplashScreen>
  );
};
export default LoginSplashScreen;
