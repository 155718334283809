/** @jsx jsx */
import React, { useState } from "react";
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import ReactPhoneNumberInput from "react-phone-number-input/input";
import ReactCodeInput from "react-code-input";
import trash from "@src/assets/trash.svg";
import close from "@src/assets/Close.svg";
import infoIcon from "@src/assets/info.svg";
import Tooltip from "rc-tooltip";
import { isExistsProtocol } from "@utils";
import RightArrow from "@components/RigthArrow";

const textStyle = css`
  font-family: Graphik LCG;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const inputStyle = css`
  outline: none;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  border: 1px solid #f7f7f7;
  ${textStyle};
  padding: 0 12px;
  box-sizing: border-box;
  appearance: none;
  background: #f7f7f7;
  color: #545961;

  :focus {
    color: #000000;
    background: white;
    border: 1px solid #0052cc;
  }

  ::placeholder {
    color: #545961;
  }
`;

export const TextArea = styled.textarea<{ invalid?: boolean }>`
  ${inputStyle};
  height: 113px;
  padding: 12px;
  resize: none;
  background: ${({ invalid }) => (invalid ? "#FFE6E7" : "#f7f7f7")};
  color: ${({ invalid }) => (invalid ? "#FF0000" : "#000000")};
`;

// @ts-ignore
export const CodeInput = styled(ReactCodeInput as any)<{ invalid?: boolean }>`
  margin: 0 -4px;
  display: flex !important;
  justify-content: space-between;
  flex-wrap: nowrap;

  & > * {
    ${inputStyle};
    padding: unset;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    margin: 0 4px;
    box-sizing: border-box;
    flex: 1;
    width: 10%;
    height: 48px;
    background: ${({ invalid }) => (invalid ? "#FFE6E7" : "#f7f7f7")};
    color: ${({ invalid }) => (invalid ? "#FF0000" : "#000000")};

    :focus {
      color: #000;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

type TInputType = "string" | "phone" | "link";

interface IProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  icon?: string;
  error?: string;
  tips?: string;
  onTrashClick?: () => void;
  onCrossClick?: () => void;
  onArrowClick?: () => void;
  info?: string;
  prefix?: string;
  onChange?: (v: string) => void;
  inputType?: TInputType;
}

const Root = styled.div<{
  focused?: boolean;
  invalid?: boolean;
  inputType?: TInputType;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  height: 48px;
  background: ${({ focused, invalid }) =>
    focused ? "#fff" : invalid ? "#FFE6E7" : "#f7f7f7"};
  border: 1px solid
    ${({ focused, invalid }) =>
      invalid ? "#FF0000" : focused ? "#0500FF" : "#f7f7f7"};
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  outline: none;

  .input {
    border: none;
    background: transparent;
    outline: none;
    width: 100%;
    ${textStyle};
    appearance: none;
    color: ${({ invalid, inputType }) =>
      invalid ? "#FF0000" : inputType === "link" ? "#0500FF" : "#000000"};
    padding-left: 0;

    :focus {
      background: white;
    }

    :disabled {
      color: #545961;
    }

    ::placeholder {
      color: #545961;
    }
  }
`;

const ErrorText = styled.div`
  font-family: Inter, Graphik LCG;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #ff0000;
  opacity: 0.9;
  position: absolute;
  top: 55px;
  left: 0;
`;

const PrefixText = styled.div`
  ${textStyle};
  line-height: 30px;
  color: #545961;
`;

const Icon = styled.img<{ width: number }>`
  width: ${({ width }) => width}px;
  cursor: pointer;
`;

const Input: React.FC<IProps> = ({
  onTrashClick,
  onCrossClick,
  onArrowClick,
  info,
  icon,
  error,
  tips,
  prefix,
  onChange,
  autoFocus,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const result: JSX.Element[] = [];
  const getPrefix = () => {
    if (icon != null) {
      result.push(
        <Icon
          key="icon"
          style={{ marginRight: 8, height: 32 }}
          width={32}
          src={icon}
          alt="icon"
        />
      );
    }
    if (prefix != null) {
      result.push(<PrefixText key="prefix">{prefix}</PrefixText>);
    }
    return result;
  };
  const getPostfix = () => {
    if (onTrashClick != null) {
      return (
        <Icon width={17.42} src={trash} alt="trash" onClick={onTrashClick} />
      );
    }
    if (onCrossClick != null) {
      return (
        <Icon width={17.42} src={close} alt="close" onClick={onCrossClick} />
      );
    }
    if (onArrowClick != null) {
      return <RightArrow onClick={onArrowClick} />;
    }
    if (info != null) {
      return (
        <Tooltip placement="left" trigger={["click"]} overlay={info}>
          <Icon width={20} src={infoIcon} alt="info" />
        </Tooltip>
      );
    }
    return null;
  };
  return (
    <Root focused={focused} invalid={error != null} {...rest}>
      {getPrefix()}
      {rest.inputType === "phone" ? (
        <ReactPhoneNumberInput
          className="input"
          placeholder={prefix == null ? rest.placeholder : undefined}
          onFocus={(e: any) => {
            rest.onFocus && rest.onFocus(e);
            setFocused(true);
          }}
          onBlur={(e: any) => {
            rest.onBlur && rest.onBlur(e);
            setFocused(false);
          }}
          onChange={onChange}
          value={rest.value}
          readOnly={rest.readOnly}
          disabled={rest.disabled}
          autoFocus={autoFocus}
          withCountryCallingCode
          international
        />
      ) : (
        <input
          type={rest.type}
          className="input"
          placeholder={prefix == null ? rest.placeholder : undefined}
          onFocus={(e) => {
            rest.onFocus && rest.onFocus(e);
            setFocused(true);
          }}
          onBlur={(e) => {
            rest.onBlur && rest.onBlur(e);
            setFocused(false);
          }}
          onChange={(e) => {
            e.target.value =
              prefix != null ? prefix + e.target.value : e.target.value;
            onChange && onChange(e.target.value);
          }}
          value={
            prefix != null && rest.value != null
              ? rest.value
                  .toString()
                  .replace(
                    isExistsProtocol(rest.value as string)
                      ? "https://" + prefix
                      : prefix,
                    ""
                  )
                  .replace(prefix, "")
              : rest.value
          }
          readOnly={rest.readOnly}
          disabled={rest.disabled}
          autoFocus={autoFocus}
        />
      )}
      {getPostfix()}
      {error ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <ErrorText style={{ color: "#545961" }}>{tips}</ErrorText>
      )}
    </Root>
  );
};

export default Input;
