import styled from "@emotion/styled";

const FieldsDescription = styled.div`
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #8c929b;
`;

export default FieldsDescription;
