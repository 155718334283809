import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import useLanguage from "@src/hooks/useLanguage";

const SendAgain = styled.div`
  font-family: Graphik LCG, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2934d0;
  margin-top: 5px;
  margin-bottom: 22px;
  cursor: pointer;
`;

const Subtitle = styled.div`
  font-family: Graphik LCG;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 22px;
`;

const TimeoutButton: React.FunctionComponent<{ onClick: () => void }> = ({
  onClick,
}) => {
  const timeout = 50; //sec
  const dictionary = useLanguage();

  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);

  const reset = () => {
    setSeconds(0);
    setIsActive(true);
  };

  useEffect(() => {
    let interval: any = null;
    if (isActive) {
      interval = setInterval(
        () =>
          seconds !== timeout - 1
            ? setSeconds((seconds) => seconds + 1)
            : setIsActive(false),
        1000
      );
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const handleClick = async () => {
    await onClick();
    reset();
  };

  const restTime = timeout - seconds;

  return isActive ? (
    <Subtitle>
      {dictionary.newSmsCode}
      {restTime >= 10 ? ` 0:${restTime}` : ` 0:0${restTime}`}
    </Subtitle>
  ) : (
    <SendAgain onClick={handleClick}>{dictionary.newSmsCode}</SendAgain>
  );
};

export default TimeoutButton;
