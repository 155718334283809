import styled from "@emotion/styled";

const FieldValue = styled.div`
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 5px 0;
  cursor: pointer;
`;

export default FieldValue;
