export const isExistsProtocol = (str: string) =>
  new RegExp("^(https?:\\/\\/)").test(str ?? "");

export function validURL(str?: string) {
  try {
    str = !isExistsProtocol(str ?? "") ? "https://" + str : str;
    new URL(str ?? "");
    return true;
  } catch (e: any) {
    return false;
  }
}

export const getLinkById = (id: string) =>
  `https://kasanie.info/card/${id}`;

// export function validateURL(str?: string | null) {
//   if (str == null) return false;
//   const pattern = new RegExp(
//     "^(https?:\\/\\/)?" + // protocol
//       "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
//       "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
//       "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
//       "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
//       "(\\#[-a-z\\d_]*)?$",
//     "i"
//   ); // fragment locator
//   return !!pattern.test(str);
// }
export function validateEmail(str: string) {
  const checkEmail = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return checkEmail.test(str);
}

export function toFile(b64: string) {
  var arr = b64.split(","),
    mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], "file", { type: mime });
}

export const toBase64 = (
  file: File
): Promise<string> => // | ProgressEvent<FileReader>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export function compressImage(base64: string): Promise<string> {
  const canvas = document.createElement("canvas");
  const img = document.createElement("img");

  return new Promise((resolve, reject) => {
    img.onload = function () {
      let width = img.width;
      let height = img.height;
      const maxHeight = 800;
      const maxWidth = 900;

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx!.drawImage(img, 0, 0, width, height);

      resolve(canvas.toDataURL("image/jpeg", 0.7));
    };
    img.onerror = function (err) {
      reject(err);
    };
    img.src = base64;
  });
}
