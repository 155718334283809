import styled from "@emotion/styled";
import React, { useState } from "react";
import AddButton from "@components/AddButton";
import { useObserver } from "mobx-react-lite";
import { useUpdateScreenVM } from "@src/screens/UpdateScreen/UpdateScreenVM";
import ToggleArrow from "@components/ToggleArrow";
import { Row } from "@components/flex";
import { ISocialLink } from "@src/models";
import SocialLinksList from "@components/UpdateAddLinkTab/SocialLinksList";
import EditLinkDialog from "@components/UpdateAddLinkTab/LinkInput/EditLinkDialog";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  margin: -4px 0;

  & > * {
    margin: 4px 0;
  }
`;
const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

const SocialLinksTab: React.FC<IProps> = () => {
  const vm = useUpdateScreenVM();
  const dictionary = useLanguage();
  const [opened, setOpened] = useState(true);
  const [editingLink, setEditingLink] = useState<{
    link: ISocialLink;
    index?: number;
  } | null>(null);

  const handleAddClick = () =>
    setEditingLink({ link: { value: "", type: "custom" } });

  const handleSave = (link: ISocialLink) => {
    if (editingLink?.index == null) {
      vm.card.socialLinks!.push(link);
    } else {
      vm.card.socialLinks![editingLink!.index] = link;
    }
  };

  return useObserver(() => (
    <Root>
      <Row
        onClick={() => setOpened(!opened)}
        style={{ marginBottom: 21, cursor: "pointer", userSelect: "none" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Title>{dictionary.socialMediaTab.title}</Title>
        <ToggleArrow opened={opened} />
      </Row>
      {opened && (
        <React.Fragment>
          <SocialLinksList
            onChange={(links) => (vm.card.socialLinks = links)}
            links={vm.card.socialLinks ?? []}
            onStartEdit={setEditingLink}
            onDelete={(index: number) => vm.card.socialLinks?.splice(index, 1)}
          />
          <AddButton onClick={handleAddClick}>{dictionary.add}</AddButton>
        </React.Fragment>
      )}
      {editingLink != null && (
        <EditLinkDialog
          isPaymentLink={false}
          creationMode={editingLink.index == null}
          defaultType={editingLink.link.type}
          defaultValue={editingLink.link.value}
          defaultDisplayName={editingLink.link.displayName}
          onClose={() => setEditingLink(null)}
          onSave={handleSave}
        />
      )}
    </Root>
  ));
};
export default SocialLinksTab;
