import styled from "@emotion/styled";
import React, { createRef } from "react";
import { useObserver } from "mobx-react-lite";
import useOutsideClick from "@src/hooks/useOutsideClick";
import { Row } from "@components/flex";
import Title from "@components/fontComponents/Title";
import { ReactComponent as Close } from "@src/assets/Close.svg";
import { MAX_WIDTH } from "@components/Layout";

interface IProps {
  onClose: () => void;
  title: string;
  subtitle?: string;
}

const Root = styled.div`
  display: flex;
  padding: 24px 21px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 5%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #ffffff;
  box-shadow: 0px -6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px 16px 0 0;
`;
const Background = styled.div`
  position: fixed;
  z-index: 8;
  background: #ffffff;
  top: -10px;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Subtitle = styled.div`
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 28px;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  max-width: ${MAX_WIDTH}px;
`;
const Modal: React.FC<IProps> = ({ onClose, title, subtitle, ...rest }) => {
  const ref = createRef<HTMLDivElement>();
  useOutsideClick(ref, onClose);

  return useObserver(() => (
    <Background>
      <Root ref={ref}>
        <Row
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingBottom: 23, maxWidth: MAX_WIDTH }}
        >
          <Title>{title}</Title>
          <Close onClick={onClose} fill="red" />
        </Row>
        <Subtitle>{subtitle}</Subtitle>
        <Body>{rest.children}</Body>
      </Root>
    </Background>
  ));
};

export default Modal;
