import React from "react";
import Button, { BUTTON_TYPES } from "@components/Button";
import styled from "@emotion/styled";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { ROUTES } from "@stores/RouterStore";
import SplashScreen from "@components/SplashScreen";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import Subtitle from "@components/fontComponents/Subtitle";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {}

const Privacy = styled(Subtitle)`
  color: #ffffff;
  cursor: pointer;
  padding-top: 10px;
`;
const RegisterSplashScreen: React.FC<IProps> = () => {
  const { accountStore, cardStore } = useStores();
  const { cardId } = useParams<{ cardId: string }>();
  const history = useHistory();
  const dictionary = useLanguage();
  const card = useObserver(() => cardStore.card);
  const user = useObserver(() => accountStore.user);
  if (card.userId)
    return <Redirect to={ROUTES.CARD.replace(":cardId", card.id)} />;
  return (
    <SplashScreen
      title={dictionary.welcome}
      subtitle={dictionary.registerToActivate}
    >
      {user ? (
        <Button
          buttonType={BUTTON_TYPES.WHITE}
          onClick={() =>
            cardStore
              .registerCardById(cardId)
              .then(() => history.push(ROUTES.EDIT))
              .catch(() => history.push(ROUTES.OOPS))
          }
          style={{ marginTop: 25 }}
        >
          {dictionary.register}
        </Button>
      ) : (
        <>
          <Button
            onClick={() =>
              accountStore
                .signInWithApple()
                .then(() => cardStore.registerCardById(cardId))
                .then(() => history.push(ROUTES.EDIT))
                .catch((e) => history.push(ROUTES.OOPS))
            }
            buttonType={BUTTON_TYPES.WHITE}
            style={{ marginTop: 25 }}
          >
            {dictionary.registerApple}
          </Button>
          <Button
            onClick={() =>
              accountStore
                .signInWithGoogle()
                .then(() => cardStore.registerCardById(cardId))
                .then(() => history.push(ROUTES.EDIT))
                .catch((e) => history.push(ROUTES.OOPS))
            }
            buttonType={BUTTON_TYPES.WHITE}
            style={{ marginTop: 25 }}
          >
            {dictionary.registerGoogle}
          </Button>
          <Button
            buttonType={BUTTON_TYPES.WHITE}
            onClick={() =>
              history.push(ROUTES.REGISTER_EMAIl.replace(":cardId", cardId))
            }
            style={{ marginTop: 25 }}
          >
            {dictionary.registerEmail}
          </Button>
           {/*<Button
            onClick={() =>
              history.push(ROUTES.REGISTER_PHONE.replace(":cardId", cardId))
            }
            buttonType={BUTTON_TYPES.WHITE}
            style={{ marginTop: 25 }}
          >
            {dictionary.registerPhone}
          </Button>*/}
          <Privacy onClick={() => history.push(ROUTES.PRIVACY)}>
            {dictionary.policyRegister}
          </Privacy>
        </>
      )}
    </SplashScreen>
  );
};
export default RegisterSplashScreen;
