import styled from "@emotion/styled";

const Subtitle = styled.div`
  font-family: Graphik LCG, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`;

export default Subtitle;
